.option {
  margin-bottom: 28px;
  &__image {
    margin-left: -8px;
    z-index: 100;
    img {
      width: 80px;
    }  
  }
  &__info {
    &_title {
      font-weight: 400 !important;
      color: #00ADEF;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid #00ADEF;

    }
  }
}

@media (min-width: 992px) {
  .options {
    .left {
      .option {
        margin-bottom: 220px;
      }
    }
    .right {
      .option {
        margin-top: 250px;
      }
    }
  }
  .option {
    &__image {
      margin-left: 0;
      img {
        width: 200px;
      }     
    }
    &__info {
      position: relative;
      &_title {
        border-bottom: none;  
      }

      .line {
        position: absolute;
        border-bottom: 1px solid #00ADEF;
        left: -100px;
        right: 0;
        z-index: 0;
        bottom: 0;
      }

      &_desc {
        padding-left: 200px;
        margin-top: -24px;
        font-size: 18px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .options {
    .left {
      .option {
        margin-bottom: 270px;
      }
    }
    .right {
      .option {
        margin-top: 300px;
      }
    }
  }
  .option {
    &__image {
      img {
        width: 250px;
      }     
    }
    &__desc {
      padding-left: 250px;
      font-size: 22px;
    }
  }
}