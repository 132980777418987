.case-card {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 12px;
  height: 125px;

  &__image {
    width: 50%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__content {
    &_title {
      font-weight: 300 !important;
      color: #00ADEF;
    }
    &_desc{
      font-size: 14px;
    }
  }
}

@media (min-width: 768px) {
  .case-card {
    width: calc(100% - 24px) !important;
    padding: 28px;
    height: 226px;
    margin-right: 24px;

    &__content {
      &_desc{
        font-size: 18px;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}