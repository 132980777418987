.header {
  .email {
    cursor: pointer;
  }
  
  &__items {
    flex: 1 0 auto !important;

    .nav-link {
      color: #333333;
    }
    .nav-link:hover, .nav-link:focus {
      color: #00ADEF;
      border-bottom: 1px solid #00ADEF;

    }
    a {
      padding: 8px;
    }
  }
}

@media (min-width:1200px){
  .header {

    &__items {
      padding: 0 100px !important;
    }
  }
}