@import "../../../../../node_modules/bootstrap/scss/bootstrap";

.section-one {
  &__image {
    width: 50%;
    background-image: url('../../../../assets/images/computer.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 320px;
    width: 320px;
    margin-top: -24px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__text-part {
    &>div, &>button {
      margin-bottom: 25px;
    }
  }
  .actions {
    .action {
      padding: 10px 10px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50px; 
        border: 2px solid transparent;
        background: linear-gradient(45deg,#00ADEF, #FFE167, #FF6D6D) border-box;
        -webkit-mask:
          linear-gradient(#fff 0 0) padding-box, 
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
      }
    }
  }
}

.advantages {
  .advantage {
    margin-bottom: 16px;
    &__icon {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
  }
}

@include media-breakpoint-up(md) { 
  .section-one {
    &__image {
      height: 600px;
      width: 600px;
      margin-top: 0;
    }
    &__text-part {
      width: 50%;
    }

    .actions {
      .action {
        padding: 12px 20px;
      }
    }
  }
  

  .advantages {
    .advantage {
      &__icon {
        width: 75px;
        height: 75px;
        margin-right: 16px;
        margin-top: 12px;
      }
    }
  }
}