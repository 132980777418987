.burger-menu {
  position: sticky;
  bottom: 0;
  z-index: 100;
  background-color: #F5F5F5;
  height: 85px;
  &__modal {
    width: 85px;
    img {
      width: 100%;
    }
  }
  &__menu {
    width: 85px;
    img {
      width: 100%;
    }
  }
}