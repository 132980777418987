.offcanvas{
  width: 300px !important;

  .burger-menu-list {
    height: 100%;
    position: relative;
    padding: 52px 16px;

    .nav-link {
      margin-bottom: 18px;
    }

    .email {
      position: absolute;
      bottom: 52px;
      left: 16px;
      cursor: pointer;
    }

    .social {
      background-image: url('../../../assets/images/linked-black.png');
      width: 24px;
      height: 24px;
      background-size: contain;
      position: absolute;
      bottom: 26px;
      left: 16px;
    }

    &__close {
      width: 24px;
      height: 24px;
      background-image: url('../../../assets/images/close.png');
      background-size: contain;
      cursor: pointer;  
    }
  }

  .modal-content {
    width: 300px !important;

  }
}