.modal-request {
  padding: 52px 16px;
  background-image: url('../../../assets/images/background-modal.png');
  background-repeat: repeat-y;
  background-size: contain;
  background-position-x: right;

  &__close {
    width: 24px;
    height: 24px;
    background-image: url('../../../assets/images/close.png');
    background-size: contain;
    cursor: pointer;
  }

  form {
    .form-label {
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 200;
      font-size: 16px;
      line-height: 22px;
      color: rgba(51, 51, 51, 0.4);
      margin-left: 8px;
    }

    .form-control {
      border: 1px solid #ADADAD;
      border-radius: 60px;
      
      &::placeholder {
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 200;
        font-size: 16px;
        color: rgba(51, 51, 51, 0.4);
      }
      &:focus {
        border-color: #00ADEF;
        box-shadow: 0 0 0 0.25rem rgba(0, 173, 239, 0.2)
      }
    }
    .form-check-input {
      &:checked {
        background-color: #fff;
        border-color: #00ADEF;
      }
    }
    .form-check-input:checked[type=checkbox] {
      --bs-form-check-bg-image: url('../../../assets/images/checked.png');
      background-size: 10px;
    }
  }
}

.fade { 
  .custom-modal-dialog {
    transform: translate(0) !important;
  }
}

@media (min-width: 768px) {
  .custom-modal-dialog {
    margin-right: 0 !important;
    margin-top: 0 !important;

    .modal-request {
      padding: 52px 110px 52px 56px;

      &__body {
        width: 90%;
      }
    }
  } 
}

@media (min-width:992px){
  .custom-modal-dialog {
    max-width: 900px !important;

    .modal-request {
      padding-right: 250pх;

      &__body {
        padding-right: 36px;
      }
    }
  } 
}