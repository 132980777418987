.slider {
  width: calc(100% - 6vmin);
  height: 680px;
  margin: 3vmin;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}

.slide {
  cursor: pointer;
  min-width: 60px;
  flex: 1 1;
  transition-property: flex-grow, color;
  transition-duration: 0.6s;
  transition-timing-function: ease-in;
  color: white;
  font-size: 20px;

  display: flex;
  justify-content: space-between;

  .header {
    width: 60px;
    font-weight: 400;
    position: relative;

    &__text {
      margin-top: 50px;
      transform: rotate(90deg);
      white-space: nowrap;
    }
    &__arrow {
      position: absolute;
      bottom: 40px;
      right: 22px;
      width: 16px;

      &_rotate{
        transform: rotate(180deg);
      }

      img {
        width: 100%;
      }
    }
  }

  .content {
    display: none;
    padding: 40px;

    .small-title {
      font-weight: 400;
      font-size: 32px;
    }

    .image{
      // min-height: 200px;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .btn {
      margin-left: 75%;
    }
  }
}

.slide--expanded {
  flex: 100 1;
  color: var(--color-text);

  background: #F5F5F5 !important;

  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: #333333;
  }

  .header {
    color: #00ADEF;
  }

  &:nth-of-type(1) {
    border-radius: 10px 0 0 10px;
    border: 1px solid #00ADEF;
  }
  &:nth-of-type(5) {
    border-radius: 0 10px 10px 0;
    border: 1px solid #00ADEF;
  }
}


@media (max-width: 992px) {
  .slider {
    height: auto;
    margin: 3vmin;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
  }

  .slide {  
    flex-direction: column;

    .header {
      padding: 8px 16px;
      font-size: 16px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__text {
        margin-top: 0;
        transform: rotate(0);
        order: 2;
      }
      &__arrow {
        position: static;
        width: 8px;
        transform: rotate(90deg);
        order: 1;
  
        &_rotate{
          transform: rotate(-90deg);
        }
  
        img {
          width: 100%;
        }
      }
    }

    .content {
      padding: 12px 16px;
      height: auto;

      .small-title {
        font-size: 22px;
      }

      .description {
        font-size: 16px;
      }

      .image{
        height: 170px;
      }

      .btn {
        margin-left: 0;
      }
    }
  }

  .slide--expanded {  
    .content {
      padding-bottom: 8px;
    }

    .header {
      padding-top: 0;
    }

    &:nth-of-type(1) {
      border-radius: 10px 10px 0 0;
    }

    &:nth-of-type(5) {
      border-radius: 0 0 10px 10px;
    }
  }
}

@media (min-width: 1400px) {
  .slider {
    height: 750px;
  }
}


.slide:nth-of-type(1) {
  background-color:#3DBFF0;
  --color-text: white;
}

.slide:nth-of-type(2) {
  background-color: #54C6F1;
  --color-text: white;
}

.slide:nth-of-type(3) {
  background-color: #78D0F2;
  --color-text: white;
}

.slide:nth-of-type(4) {
  background-color: #93D8F3;
  --color-text: white;
}
.slide:nth-of-type(5) {
  background-color: #9fdaf2;
  --color-text: white;
}
