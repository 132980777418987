.case-page {
  .small-title {
    color: #00ADEF;
    font-weight: 400;
    margin-bottom: 16px;
  }
  &__first-part {
    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F5F5F5;
      box-shadow: 0px 18px 162px rgba(0, 0, 0, 0.05), 0px 2.25388px 20.2849px rgba(0, 0, 0, 0.025);
      border-radius: 20px;
      min-height: 343px;

      img {
        width: 80%;
      }
    }

    .tasks__text {
      background: #FFFFFF;
      border-radius: 20px;
      padding: 16px 32px;
    }
  }

  .number {
    div {
      color: #00ADEF;
      font-weight: 400;
      width: 50px;
      height: 50px;
      padding: 14px;
      background-color: #FFFFFF;
      box-shadow: 10px 2px 5px rgba(0, 173, 239, 0.1);
      border-radius: 50%;
    }
  }
}

@media (min-width: 768px) {
  .case-page {
    &__first-part {
      display: flex;
      flex-wrap: nowrap !important;

      .image {
        order: 2;
        padding: 60px 0;
      }
      .desc {
        order: 1;
        margin-right: 24px !important;
      }
    }

    &__second-part {
      display: flex;

      .solutions {
        flex: 1;
      }
      
      .effects {
        flex: 1 ;
      }
    }

    .number {
      width: 70px;
      height: 70px;
      div {
        width: 70px;
        height: 70px;
        padding: 17px;
        font-size: 24px;
      }
    }
  }
}

@media (min-width:992px){
  .case-page {
    &__second-part {
      .number {
        width: 100px;
        height: 100px;
        div {
          width: 100px;
          height: 100px;
          padding: 29px;
          font-size: 28px;
        }
      }
    }
  }
}