.footer {
  color: #FFFFFF;
  height: 366px;
  width: 100%;
  padding: 20px 0 55px;

  .social {
    background-image: url('../../assets/images/linked-white.png');
    width: 24px;
    height: 24px;
    background-size: contain;
  }

  .rights {
    font-size: 14px;
  }

  hr {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .footer {
    padding: 72px 0 42px;
    font-weight: 400;

    .logo {
      height: 40px;
      width: 120px;
    }

    .contact {
      font-weight: 400;
    }

    .request {
      width: fit-content;
    }

    .email {
      font-size: 18px;
      text-align: end;
      cursor: pointer;
    }

    .social {
      width: 36px;
      height: 36px;
    }
    .rights {
      font-size: 16px;
    }
    .address {
      font-size: 18px;
      text-align: end;
    }
  }
}