.swiper-custom-element {
  height: 43px;
}
.swiper-icon {
  height: 100%;
  object-fit: contain;
}

@media (min-width:768px){
  .swiper-custom-element {
    height: 53px;
  }
}