@import "../../../node_modules/bootstrap/scss/bootstrap";

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 28px;
  &__accent {
    color: #00ADEF;
  }
  &_light {
    font-weight: 300;
  }
}

@include media-breakpoint-up(md) { 
  .title {
    font-size: 40px;
    margin-bottom: 40px;
    line-height: 50px;
  }
}

@include media-breakpoint-up(xl) { 
  .title {
    font-size: 55px;
    margin-bottom: 50px;
    line-height: 65px;
  }
}

@include media-breakpoint-up(xxl) { 
  .title {
    font-size: 60px;
    margin-bottom: 50px;
    line-height: 80px;
  }
}