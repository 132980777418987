.person-card {
  &__description {
    &_name {
      font-weight: 400 !important;
    }
  
    &_text {
      background-color: white;
      padding: 16px 32px;
      border-radius: 20px;
    }

    &_job-title {
      color: rgba(51, 51, 51, 0.7);
      font-weight: 400;
    }
  }

  &__image {
    height: 163px;

    img {
      height: 100%;
    }
  }
}

@media (min-width: 768px) {
  .person-card {
    &__image {
      order: 1;
      height: 330px;
      margin-right: 50px;
    }
    &__description {
      order: 2;
      margin-right: 0;
    }

    &:nth-child(2n){
      .person-card {
        &__description {
          order: 1;
          margin-right: 50px;
        }
        &__image{
          order: 2;
          margin-right: 0;
        }
      }
    }

    &:nth-child(2n+1){
      .person-card {
        &__description {
          margin-right: 0;
        }
        &__image{
          margin-right: 50px;
        }
      }
      }
  }  
}

@media (min-width: 1400px) {
  .person-card {
    &__image {
      height: 450px;
      margin-right: 100px;
    }
    &__description {
      font-size: 28px;
    }

    &:nth-child(2n){
      .person-card {
        &__description {
          margin-right: 100px;
        }
      }
    }

    &:nth-child(2n+1){
      .person-card {
        &__image{
          margin-right: 100px;
        }
      }
      }
  }
}