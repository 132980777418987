@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;300;400;500;600;700&display=swap');
@import "../../node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    color: inherit;
    text-decoration: none;
  }
  
  .App {
    display: flex;
    flex-direction: column;
  }
  
  header {
    background: #F5F5F5;
  }

  footer {
    flex: 0 0 auto;
    background-color: #3DBFF0;
    border-radius: 20px 20px 0px 0px;
  }

  main {
    flex: 1 0 auto;
    background: #F5F5F5;
    padding-top: 24px;
    background-image: url('../assets/images/background.png');
    background-repeat: repeat-y;
    background-size: cover;
  }

  .big-title {
    font-weight: 400;
    font-size: 28px;
  }

  .small-title {
    font-weight: 300;
    font-size: 22px;
  }
  .modal-content {
    overflow-y: auto;
  }
  .text_blue {
    color: #00ADEF;
  }
}

body,.root,.App {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width:576px){
  .container-custom {
    margin: 0;
  }
 }
 
 @media (min-width: 768px) {
  body {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  body {
    header {
      background-color: white;
    }
  }
}

@media (min-width:1200px){
  body {
    main {
      padding-top: 80px;
    }
    
    .small-title {
      font-weight: 300;
      font-size: 28px;
    }
  }
}

